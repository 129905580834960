.container {
  text-align: center;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
}

.selectFile {
  font-family: Open Sans;
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: center;
  color: #1b4090;
}

.dropzone {
  width: 100%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.dropzoneContent {
  background-color: white;
  padding: 2rem;
  cursor: pointer;
  border-radius: 0.5rem;
  border: 1px dashed #d9d9d9;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.buttonParcourir {
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.05em;
  text-align: center;
  color: #7e7e7e;
  background-color: #d9d9d9;
  width: 100%; /* Make sure the button takes up the full width */
}

.label {
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #444444;
  margin-top: 1rem;
}

.cols {
  width: 25rem;
}

.verifyButton {
  background-color: #1b9038;
  color: white;
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: center;
  margin: 0 1rem;
}

.categorie {
  text-transform: uppercase;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 2px;
  height: 3rem;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  cursor: pointer;
  border-bottom: #292f6e 3px solid;
  transition: all 0.3s ease-in-out;
  margin-inline: 1rem;
  margin-bottom: 2rem;
}

.childCateg {
  background-color: #1b4090;
  margin: 2rem;
  color: white;
  border-radius: 0.5rem;
  padding: 0.5rem;
  font-weight: 600;
  font-size: 1rem;
  width: 30%;
}

.categorie:hover,
.categorie:focus {
  background-color: #e1ebff;
}

.filename {
  font-weight: 600;
  color: #1b4090;
  font-size: 0.8rem;
}

.errorrow {
  display: flex;
  flex-direction: column;
  background-color: #efefef;
  width: 500px;
  justify-content: center;
  padding: 1rem;
  border-radius: 0.5rem;
  margin-block: 1rem;
  margin-bottom: 2rem;
}

.radioButtons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  min-width: 20rem;
  margin-bottom: 1rem;
}

.activeButton {
  text-decoration: underline;
}

.button {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 0.5rem;
  margin-right: 1rem;
  color: white;
}

.table {
  margin-bottom: 2rem;
}