.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
}

.tableContainer {
  width: 100%;
  max-width: 1200px;
}

.button {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 0.5rem;
  margin-right: 1rem;
  color: white;
}
.dropzone {
  width: 100%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.dropzoneContent {
  background-color: white;
  padding: 2rem;
  cursor: pointer;
  border-radius: 0.5rem;
  border: 1px dashed #d9d9d9;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.buttonParcourir {
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.05em;
  text-align: center;
  color: #7e7e7e;
  background-color: #d9d9d9;
  width: 100%;
}

.label {
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #444444;
  margin-top: 1rem;
}

.cols {
  width: 25rem;
}

.selectFile {
  font-family: Open Sans;
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: center;
  color: #1b4090;
}

.filename {
  font-weight: 600;
  color: #1b4090;
  font-size: 0.8rem;
}