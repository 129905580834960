.header {
  background-position: center center;
  position: sticky;
  top: 0;
  background-color: #1b4090;
  min-height: 90px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.headerLogo {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center;
  flex-grow: 1; /* Occupy remaining space */
  text-align: center; /* Center text */
  margin: 0; /* Reset margin */
  padding: 0; /* Reset padding */
}
.headerTitle {
  font-family: Open Sans;
  font-size: 18px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: center;
  color: white;
}
.headerButton {
  display: flex;
  align-items: center;
}
.logoutIcon {
  margin-right: 1rem;
  font-size: 1.5rem;
  color: gray;
  cursor: pointer;
}
.addIcon {
  margin-left: 1rem;
  font-size: 1.8rem;
  color: #1b9038;
  cursor: pointer;
}

.createAccountButton {
  margin-left: 1rem;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  background-color: #1b9038;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 0.4rem;
}

.createAccountButton:hover {
  background-color: #176f2e;
}
