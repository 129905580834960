.footer {
  background-color: #f0f0f0;
  text-align: center;
  padding: 20px;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  margin-top: 20px;
}

.content {
  margin-top: 20px; 
}

.link {
  margin: 0 10px;
  color: #333;
  text-decoration: none;
}
