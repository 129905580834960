/* Signup.module.css */

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.form{
  border: 1px solid #d9d9d9;
  border-radius: 20px;
  box-shadow: 0px 0px 5px 0px #d9d9d9;
  padding: 30px;
}

.title {
  font-size: 24px;
  margin-bottom: 20px;
}

.input {
  width: 300px;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  width: 100%;
}

.button {
  width: 150px;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.loginLink {
  margin-top: 20px;
  font-size: 14px;
}

.loginLink a {
  color: #1b4090;
  text-decoration: none;
}

.loginLink a:hover {
  text-decoration: underline;
}
